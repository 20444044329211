"use strict";
exports.__esModule = true;
exports.APPOINTMENT_TIPS_URLS = exports.SUPPORT_FORM_PROGRAM_CHANGE_REQUEST = exports.SUPPORT_FORM_ASK_FOR_DATA_URL = exports.SUPPORT_FORM_UPDATE_ACCOUNT = exports.GETTING_STARTED_HELP = exports.SCOUT_FIRST_PROSPECT_HELP_URL = exports.INVITE_TEAM_HELP_URL = exports.DOWNLOAD_APP_HELP_URL = exports.IMPRESSIONS_TIPS_URLS = exports.DEMOGRAPHIC_DATA_TIPS_URLS = exports.CONTACT_TIPS_URLS = exports.NOTES_TIPS_URLS = exports.TAGS_TIPS_URLS = exports.SALES_TIPS_URL = exports.PRICING_URL = exports.DEMO_URL = exports.MAIL_ONBOARDING_URL = exports.GUIDED_APP_ANDROID_URL = exports.GUIDED_APP_IOS_URL = exports.GUIDED_APP_ID = exports.SCHEDULE_MAIL_DELIVERY = exports.ASSIGN_TEAM_MEMBER_URL = exports.HOW_EMAIL_WORKS_URL = exports.HELP_CENTER_URL = exports.IMPERSONATE_COMPANY_ID_HEADER = exports.LAST_BOUNDING_BOX_STORAGE_KEY = exports.DEFAULT_PROSPECT_COUNT_LIMIT = exports.DEFAULT_PAGE_SIZE = exports.MAPBOX_MAP_URL = exports.MAPBOX_SATELLITE_URL = exports.MAPBOX_TILES_MAP_URL = exports.MAPBOX_TILES_SATELLITE_URL = exports.LEADSCOUT_LOGO_RED_500W = exports.LEADSCOUT_LOGO_LIGHT_500W = exports.MAP_PREVIEW_CONTROL_MARKER_URL = exports.MAP_PREVIEW_MARKER_URL = exports.LEADSCOUT_PUBLIC_ASSETS_HOST = exports.MAPBOX_ACCOUNT_ID = exports.MAPBOX_TILES_SATELLITE_STYLE_ID = exports.MAPBOX_TILES_STREET_MAP_STYLE_ID = exports.APP_ROOT_DOMAIN = exports.LANDING_PAGE_ROOT_DOMAIN = exports.LATEST_PRIVACY_VERSION = exports.LATEST_TOS_VERSION = void 0;
exports.LATEST_TOS_VERSION = '2024-02-19';
exports.LATEST_PRIVACY_VERSION = '2022-03-01';
exports.LANDING_PAGE_ROOT_DOMAIN = 'projectby.us';
exports.APP_ROOT_DOMAIN = 'leadscoutapp.com';
exports.MAPBOX_TILES_STREET_MAP_STYLE_ID = 'ckwwa0yxc0onj14tewbwj5tmz';
exports.MAPBOX_TILES_SATELLITE_STYLE_ID = 'ckwpbu74w0tlo14rvxc9gdcmd';
exports.MAPBOX_ACCOUNT_ID = 'leadscoutinc';
exports.LEADSCOUT_PUBLIC_ASSETS_HOST = 'https://d3qcnt1prmmop6.cloudfront.net';
exports.MAP_PREVIEW_MARKER_URL = "".concat(exports.LEADSCOUT_PUBLIC_ASSETS_HOST, "/emails/marker-dot-red-15w.png");
exports.MAP_PREVIEW_CONTROL_MARKER_URL = "".concat(exports.LEADSCOUT_PUBLIC_ASSETS_HOST, "/emails/marker-dot-gray-15w.png");
exports.LEADSCOUT_LOGO_LIGHT_500W = "".concat(exports.LEADSCOUT_PUBLIC_ASSETS_HOST, "/LSLogo_light_500w.png");
exports.LEADSCOUT_LOGO_RED_500W = "".concat(exports.LEADSCOUT_PUBLIC_ASSETS_HOST, "/LSLogo_red_500w.png");
exports.MAPBOX_TILES_SATELLITE_URL = 'https://api.mapbox.com/styles/v1/leadscoutinc/ckwpbu74w0tlo14rvxc9gdcmd/tiles/256/{z}/{x}/{y}@2x';
exports.MAPBOX_TILES_MAP_URL = 'https://api.mapbox.com/styles/v1/leadscoutinc/ckwwa0yxc0onj14tewbwj5tmz/tiles/256/{z}/{x}/{y}@2x';
exports.MAPBOX_SATELLITE_URL = 'mapbox://styles/leadscoutinc/ckwpbu74w0tlo14rvxc9gdcmd';
exports.MAPBOX_MAP_URL = 'mapbox://styles/leadscoutinc/ckwwa0yxc0onj14tewbwj5tmz';
exports.DEFAULT_PAGE_SIZE = 10;
exports.DEFAULT_PROSPECT_COUNT_LIMIT = 400;
exports.LAST_BOUNDING_BOX_STORAGE_KEY = 'lastBoundingBox';
exports.IMPERSONATE_COMPANY_ID_HEADER = 'ls-impersonate-company-id';
exports.HELP_CENTER_URL = 'https://helpcenter.leadscoutapp.com';
exports.HOW_EMAIL_WORKS_URL = "".concat(exports.HELP_CENTER_URL, "/en/articles/6684566-what-is-an-automated-mail-program");
exports.ASSIGN_TEAM_MEMBER_URL = "".concat(exports.HELP_CENTER_URL, "/en/articles/6684634-assigning-a-team-member-to-a-campaign");
exports.SCHEDULE_MAIL_DELIVERY = "".concat(exports.HELP_CENTER_URL, "/en/articles/6684659-scheduling-mail-delivery");
exports.GUIDED_APP_ID = 'com.leadscout.guided';
exports.GUIDED_APP_IOS_URL = 'https://apps.apple.com/us/app/lead-scout-guided-field-sales/id1638755523?platform=iphone';
exports.GUIDED_APP_ANDROID_URL = "https://play.google.com/store/apps/details?id=".concat(exports.GUIDED_APP_ID);
exports.MAIL_ONBOARDING_URL = 'https://leadscoutapp.com/onboarding/directmail';
exports.DEMO_URL = 'https://leadscoutapp.com/demo';
exports.PRICING_URL = 'https://leadscoutapp.com/pricing';
exports.SALES_TIPS_URL = "".concat(exports.HELP_CENTER_URL, "/en/collections/3581532-field-sales-tips");
exports.TAGS_TIPS_URLS = "".concat(exports.HELP_CENTER_URL, "/en/articles/8536693-what-are-tags-and-how-should-i-use-them");
exports.NOTES_TIPS_URLS = "".concat(exports.HELP_CENTER_URL, "/en/articles/8536678-how-to-add-collaborative-notes-to-a-prospect");
exports.CONTACT_TIPS_URLS = "".concat(exports.HELP_CENTER_URL, "/en/articles/8536682-how-to-manage-contact-information-for-prospects");
exports.DEMOGRAPHIC_DATA_TIPS_URLS = "".concat(exports.HELP_CENTER_URL, "/en/articles/8536686-demographic-data-tips-for-using-it-when-you-re-scouting");
exports.IMPRESSIONS_TIPS_URLS = "".concat(exports.HELP_CENTER_URL, "/en/articles/8536790-how-to-control-mail-automations-on-each-prospect");
exports.DOWNLOAD_APP_HELP_URL = "".concat(exports.HELP_CENTER_URL, "/en/articles/8536796-add-lead-scout-as-an-app-on-your-smart-phone");
exports.INVITE_TEAM_HELP_URL = "".concat(exports.HELP_CENTER_URL, "/en/articles/8684982-getting-started-invite-your-team");
exports.SCOUT_FIRST_PROSPECT_HELP_URL = "".concat(exports.HELP_CENTER_URL, "/en/articles/8684987-getting-started-scout-your-first-prospect");
exports.GETTING_STARTED_HELP = "".concat(exports.HELP_CENTER_URL, "/en/articles/6825090-get-started-with-lead-scout");
exports.SUPPORT_FORM_UPDATE_ACCOUNT = "https://".concat(exports.APP_ROOT_DOMAIN, "/support/forms/update-account");
exports.SUPPORT_FORM_ASK_FOR_DATA_URL = "https://".concat(exports.APP_ROOT_DOMAIN, "/support/forms/data-request");
exports.SUPPORT_FORM_PROGRAM_CHANGE_REQUEST = "https://".concat(exports.APP_ROOT_DOMAIN, "/support/forms/program-change-request");
exports.APPOINTMENT_TIPS_URLS = "".concat(exports.HELP_CENTER_URL, "/en/articles/9670858-setting-an-appointment-for-a-prospect");
